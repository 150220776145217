.material-symbols-outlined.green {
  color:#B0E313;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0C173E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #F6F6F6;
  padding: 20px;
}

.App-header p{
  font-size: 22px;
  margin-top: 0;
  line-height: 110%;
  margin-bottom: 10px;
}
.App-link {
  color:#F6F6F6;
  margin-left: 10px;
}
.App-link:hover {
  color: #B0E313;
}
.App-contact {
  font-size: 18px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .App-header h1 {
    font-size: 1.5em;
  }

  .App-header p {
    font-size: 16px;
  }

  
}